import { Route, Routes } from "react-router-dom";
import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import Config from "./Config";
import moment from "moment";

import Login from "./Pages/LoginPage";
import DashBoard from "./Pages/Admin/DashBoard";
import Users from "./Pages/Admin/Users";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Profile from "./Pages/Profile";
import AddProduction from "./Pages/Admin/AddProducion";

import RegisterPage from "./Pages/RegisterPage";

import ForgotPasswordPage from "./Pages/ForgotPassword";
import OtpPage from "./Pages/Otp";
import { useIdleTimer } from "react-idle-timer";

import "./i18";
import { Remove_User } from "./Redux/actions";
import FieldAgents from "./Pages/Admin/FieldAgents";
import WarehouseManager from "./Pages/Admin/WarehouseManager";
import Farmers from "./Pages/Admin/Farmer";
import Production from "./Pages/Admin/Production";
import Harvesting from "./Pages/Admin/Harvesting";
import EditProduction from "./Pages/Admin/EditProduction";
import RawMaterial from "./Pages/WarehouseManager/RawMaterial";
import ProcessedMaterial from "./Pages/WarehouseManager/ProcessedMaterial";
import Company from "./Pages/Admin/Company";
import Marketing from "./Pages/FieldAgent/Marketing";
import StockManagement from "./Pages/FieldAgent/StockManagement";
import Transportation from "./Pages/FieldAgent/Transportation";
import Distribution from "./Pages/WarehouseManager/Distribution";
import StockManagementSelling from "./Pages/WarehouseManager/StockManagementSelling";
import Report from "./Pages/FieldAgent/Report";
import AddStockManagement from "./Pages/FieldAgent/AddStockManagement";
import EditStockManagement from "./Pages/FieldAgent/EditStockmanagement";
import AddRawMaterial from "./Pages/WarehouseManager/AddRawMaterial";
import EditRawMaterial from "./Pages/WarehouseManager/EditRawMaterial";
import AddProcessedMaterial from "./Pages/WarehouseManager/AddProcessedMaterial";
import EditProcessedMaterial from "./Pages/WarehouseManager/EditProcessedMaterial";
import AddClient from "./Pages/DocumentCollectionUser/AddClient";
import ClientList from "./Pages/DocumentCollectionUser/ClientList";
import GenerateCertificateForm from "./Pages/DocumentCollectionUser/GenerateCertificate";
import DocumentUpload from "./Pages/DocumentCollectionUser/DocumentUpload";
import Search from "./Pages/DocumentCollectionUser/Search";
import AddSample from "./Pages/LabTechnician/AddSample";
import ViewSampleDetail from "./Pages/LabTechnician/ViewSampleDetail";
import UpdateSampleDetails from "./Pages/LabTechnician/UpdateSampleDetails";
import BlNumberList from "./Pages/DocumentCollectionUser/BlNumberList";
import ViewReports from "./Pages/Admin/ViewReports";
import GenerateReport from "./Pages/Admin/GenerateReport";
import ClientListStatus from "./Pages/DocumentCollectionUser/ClientListStatus";
import Request from "./Pages/Admin/Request";
import ContainerList from "./Pages/DocumentCollectionUser/ContainerList";
import ManuallyForm from "./Pages/DocumentCollectionUser/ManuallyForm";
import EditGenerateCertificateForm from "./Pages/DocumentCollectionUser/EditGenerateCertificate";
import ActivityLogs from "./Pages/DocumentCollectionUser/ActivityLogs";

function App() {
  // Disabling the console.log(s) for the production....
  // if (
  //   Config &&
  //   Config?.domain !== "http://shipment-live.think-straight.com/"
  // ) {
  //   window.console.log = () => "";
  // }

  const user = useSelector((state) => state.UserReducer.user);

  const dispatch = useDispatch();

  const isSessionExpired = () => {
    if (user) {
      const expireAt = user?.expireAt;
      const currentTime = moment().valueOf();
      const isExpired = moment(currentTime).isAfter(expireAt);
      if (isExpired) {
        window.location.href = "/";
        dispatch(Remove_User());
      }
    }
  };

  const { getRemainingTime } = useIdleTimer({
    onIdle: () => isSessionExpired(),
    timeout: 1000 * 60 * Config.idleTime,
  });

  useEffect(() => isSessionExpired(), []);

  const UserType =
    user?.userType == 1
      ? "SUPERADMIN"
      : user?.userType == 2
      ? "ADMIN"
      : user?.userType == 3
      ? "FIELDAGENT"
      : user?.userType == 4
      ? "WAREHOUSE"
      : "USER";
  //console.log(user);
  if (!user)
    return (
      <>
        <ToastContainer
          position="top-center"
          autoClose={10000}
          hideProgressBar={true}
          newestOnTop={true}
          closeOnClick
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover={false}
        />
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route path="/get-otp" element={<OtpPage />} />

          <Route path="*" index element={<Login />} />
        </Routes>
      </>
    );

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={10000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
      />

      <Routes>
        <Route path="/login" element={<Login />} />

        {/* DOCUMENT COLLECTOR */}
        {user?.userType == Config.userType.DOCUMENTCOLLECTOR && (
          <>
            <Route path="/" element={<DashBoard />} />
            <Route path="/dashboard" element={<DashBoard />} />
            <Route
              path="/generate-certificate/:id/:blNumber"
              element={<GenerateCertificateForm />}
            />
            <Route
              path="/edit-certificate/:id/:blNumber"
              element={<EditGenerateCertificateForm />}
            />
            <Route path="/activity-logs/:blNumber" element={<ActivityLogs />} />
            <Route path="/search" element={<Search />} />
            <Route path="/add-client/:id" element={<AddClient />} />
            <Route path="/manually-form/:blNumber" element={<ManuallyForm />} />
            <Route path="/manually-form" element={<ManuallyForm />} />
            <Route path="/blNumber-list/:id" element={<BlNumberList />} />
            <Route path="/client-list" element={<ClientList />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/upload-document" element={<DocumentUpload />} />
            <Route path="/client-list/:id" element={<ClientListStatus />} />
            <Route
              path="/container-list/:id/:blNumber"
              element={<ContainerList />}
            />
          </>
        )}

        {/* ADMIN */}
        {user?.userType == Config.userType.ADMIN && (
          <>
            <Route path="/" element={<DashBoard />} />
            <Route path="/dashboard" element={<DashBoard />} />
            <Route path="/users" element={<Users />} />
            <Route
              path="/generate-certificate/:id/:blNumber"
              element={<GenerateCertificateForm />}
            />
            <Route
              path="/edit-certificate/:id/:blNumber"
              element={<EditGenerateCertificateForm />}
            />
            <Route path="/activity-logs/:blNumber" element={<ActivityLogs />} />
            <Route path="/search" element={<Search />} />
            <Route path="/add-client/:id" element={<AddClient />} />
            <Route path="/manually-form/:blNumber" element={<ManuallyForm />} />
            {/* <Route path="/manually-form" element={<ManuallyForm />} /> */}
            <Route path="/blNumber-list/:id" element={<BlNumberList />} />
            <Route path="/client-list" element={<ClientList />} />
            <Route path="/upload-document" element={<DocumentUpload />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/view-report" element={<ViewReports />} />
            <Route path="/reports/generate" element={<GenerateReport />} />

            <Route path="/add-sample/:id/:blNumber" element={<AddSample />} />
            <Route
              path="/view-sample-details/:id/:blNumber"
              element={<ViewSampleDetail />}
            />
            <Route
              path="/update-sample-details/:id/:blNumber"
              element={<UpdateSampleDetails />}
            />
            <Route path="/client-list/:id" element={<ClientListStatus />} />
            <Route path="/request" element={<Request />} />
            <Route
              path="/container-list/:id/:blNumber"
              element={<ContainerList />}
            />
          </>
        )}

        {/* Sample Collector */}
        {user?.userType == Config.userType.SAMPLECOLLECTOR && (
          <>
            <Route path="/" element={<DashBoard />} />
            <Route path="/dashboard" element={<DashBoard />} />

            <Route path="/client-list" element={<ClientList />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/add-sample/:id/:blNumber" element={<AddSample />} />

            <Route
              path="/view-sample-details/:id/:blNumber"
              element={<ViewSampleDetail />}
            />
            <Route path="/client-list/:id" element={<ClientListStatus />} />
            <Route
              path="/container-list/:id/:blNumber"
              element={<ContainerList />}
            />
          </>
        )}

        {/* Quality Assurer */}
        {user?.userType == Config.userType.QUALITYASSURER && (
          <>
            <Route path="/" element={<DashBoard />} />
            <Route path="/dashboard" element={<DashBoard />} />

            <Route path="/client-list" element={<ClientList />} />
            <Route path="/profile" element={<Profile />} />
            <Route
              path="/update-sample-details/:id/:blNumber"
              element={<UpdateSampleDetails />}
            />
            <Route path="/client-list/:id" element={<ClientListStatus />} />
            <Route
              path="/container-list/:id/:blNumber"
              element={<ContainerList />}
            />
          </>
        )}
      </Routes>

      {/* <Footer /> */}
    </>
  );
}

export default App;
