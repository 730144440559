import { useState } from "react";
import tw from "tailwind-styled-components";
import { useTranslation } from "react-i18next";
import { Formik, Field, Form, useFormikContext } from "formik";
import Loading from "../../Components/Loading";
import {
  InputGroup,
  FieldWrapper,
  Label,
  SubmitBtn,
  CombineInputGroup,
  CombineInputGridGroup,
  CombineInputThreeGroup,
} from "../../Components/Styles/InputStyles";
import {
  Underline,
  BoxContainer,
  Page,
} from "../../Components/Styles/PageStyles";
import Select from "react-select";
import Navbar from "../../Components/Navbar";
import moment from "moment";
import { useSelector } from "react-redux";
import Config from "../../Config";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import CountrySelector from "../../Components/DocumentCollectionUser/CountrySelector";

const CountrySelectorField = ({ name, backendValue }) => {
  const { setFieldValue } = useFormikContext();
  const [selectedCountry, setSelectedCountry] = useState({
    label: backendValue || "Somalia",
    value: "SO",
  });

  const handleChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
    setFieldValue(name, selectedOption.label);
  };

  return (
    <CountrySelector
      backendValue={backendValue || "Somalia"}
      onChange={handleChange}
    />
  );
};

const ProcessSelectorField = ({ name, options, initialValue }) => {
  const { setFieldValue } = useFormikContext();
  const [selectedProcess, setSelectedProcess] = useState(() => {
    if (initialValue) {
      const matchedProcess = options.find(
        (option) => option.value === initialValue
      );
      return matchedProcess || { label: "Please Select", value: "NA" };
    }
    return { label: "Please Select", value: "NA" };
  });

  const handleChange = (selectedOption) => {
    setSelectedProcess(selectedOption);
    setFieldValue(name, selectedOption.value);
  };

  return (
    <Select
      value={selectedProcess}
      onChange={handleChange}
      options={options}
      placeholder="Select process"
      className="w-full"
    />
  );
};

const EditGenerateCertificateForm = ({}) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { id, blNumber } = useParams();

  const processOptions = [
    { label: "Lab Test", value: "lab" },
    { label: "Inspection", value: "inspection" },
    { label: "Others", value: "others" },
  ];
  const [isAddMoreAvailable, setIsAddMoreAvailable] = useState(true);
  const user = useSelector((state) => state.UserReducer.user);
  const [isProductionFetched, setIsProductionFetched] = useState(false);
  const [formData, setFormData] = useState(null);
  const [rows, setRows] = useState([
    {
      containerNo: "",
      size: "",
      weight: "",
      package: "",
      country: "",
      itemDescription: "",
    },
  ]);

  const addRow = () => {
    setRows([
      ...rows,
      {
        containerNo: "",
        size: "",
        weight: "",
        package: "",
        country: "",
        itemDescription: "",
      },
    ]);
  };

  const removeRow = () => {
    if (rows.length > 1) {
      const updatedRows = [...rows];
      updatedRows.pop();
      setRows(updatedRows);
    }
  };

  const handleInputChange = (index, fieldName, value) => {
    const updatedRows = [...rows];
    updatedRows[index][fieldName] = value;
    setRows(updatedRows);
  };

  const EditProduct = async () =>
    await axios.get(
      `${Config.apiUrl}/get-billnumber-Detail/${id}/${blNumber}`,
      {
        headers: {
          Authorization: "Bearer" + " " + user.token,
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
      }
    );

  const { data, isLoading: productionLoading } = useQuery(
    "get-production",
    EditProduct,
    {
      enabled: !isProductionFetched,
      onSuccess: (data) => {
        if (data?.data?.data?.details) {
          setIsProductionFetched(true);
          setFormData(data.data.data.details);
        }
        const fetchedContainers = data.data.data.details.containers || [];
        if (fetchedContainers.length) {
          setRows(fetchedContainers);
        } else {
          setRows([
            {
              containerNo: "",
              size: "",
              weight: "",
              package: "",
              country: "",
              itemDescription: "",
            },
          ]);
        }
      },
    }
  );

  const EditGenerateFunction = async (values) => {
    const body = new FormData();
    const containers = values.containers.map((container) => ({
      ...container,
    }));
    body.append("containers", JSON.stringify(containers) || "");
    body.append("userId", user.userId);
    body.append("billLeading", values.billLeading);
    body.append("invoiceNo", values.invoiceNo);
    body.append("invoiceDate", values.invoiceDate);
    body.append("remark", values.remark);
    body.append("description", values.description);
    body.append("voucher", values.voucher);
    body.append("processOfGoods", values.processOfGoods);
    body.append("importer", values.importer);
    body.append("importerAddress", values.importerAddress);
    body.append("countryExport", values.countryExport);
    body.append("exporter", values.exporter);
    body.append("exporterAddress", values.exporterAddress);
    body.append("portArrival", values.portArrival);
    body.append("cocNo", values.cocNo);
    body.append("exempted", values.exempted);
    body.append("id", id);

    return await axios.post(
      `${Config.apiUrl}/edit-generate-certificate`,
      body,
      {
        headers: {
          Authorization: "Bearer" + " " + user.token,
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
      }
    );
  };

  const onSuccess = (res) => {
    if (res?.data?.status == "OK") {
      toast.success(res?.data?.msg || "Success");
      if (res?.data?.certificate) {
        const certificateUrl = res.data.certificate;
        window.open(certificateUrl, "_blank");
      }
      navigate("/client-list");
    } else toast.error(res?.data?.msg || "Error");
  };

  const onError = (res) => {
    toast.error(res?.response?.data?.msg || "An Error Occured");
  };

  const { isLoading: isLoadingReg, mutate: generateCerificate } = useMutation(
    EditGenerateFunction,
    {
      onSuccess: onSuccess,
      onError: onError,
    }
  );

  const removeEmptyRows = () => {
    const filteredRows = rows.filter((row) => row.containerNo.trim() !== "");
    if (filteredRows.length === 0) {
      setRows([
        {
          containerNo: "",
          size: "",
          weight: "",
          package: "",
          country: "",
          itemDescription: "",
        },
      ]);
    } else {
      setRows(filteredRows);
    }
  };

  const SubmitHandler = (values) => {
    if (values.processOfGoods === "NA" || values.processOfGoods === "") {
      toast.error("Please select the process of goods");
      return;
    }
    const emptyContainerRows = rows.filter((row) => !row.containerNo.trim());
    if (emptyContainerRows.length > 0) {
      toast.error(
        "Please enter container number for all rows or remove empty rows to submit form"
      );
      return;
    }

    const updatedValues = {
      ...values,
      containers: rows,
    };

    generateCerificate(updatedValues);
  };

  if (productionLoading || !formData) {
    return <Loading />;
  }

  const initialValues = {
    containerNo: rows,
    importer: formData?.companyName || "",
    countryExport: formData?.countryExport || "Somalia",
    importerAddress: formData?.address || "",
    billLeading: formData?.blNumber || "",
    invoiceDate:
      moment(formData?.invoiceDate).format("YYYY-MM-DD") ||
      moment().format("YYYY-MM-DD"),
    description: formData?.description || "",
    remark: formData?.remarks || "",
    voucher: formData?.voucher || "",
    exporter: formData?.exporterName || "",
    exporterAddress: formData?.exporterAddress || "",
    portArrival: formData?.portArrival || "",
    invoiceNo: formData?.invoiceNumber || "",
    processOfGoods: formData?.processOfGoods || "",
    exempted: formData?.exempted || "",
    cocNo: formData?.cocNo || "",
  };

  return (
    <>
      <Navbar />
      <Page>
        <BoxContainer>
          <Box>
            <div className="flex w-full justify-center items-center">
              <div>
                <Title>Edit Release Certificate</Title>
                <Underline />
              </div>
            </div>
            <Formik
              initialValues={initialValues}
              onSubmit={SubmitHandler}
              enableReinitialize={false}
            >
              <Form>
                <Wrapper>
                  <CombineInputGroup>
                    <InputGroup>
                      <Label htmlFor="billLeading">Bill Leading Number</Label>
                      <FieldWrapper className="bg-gray-100">
                        <Field
                          type="text"
                          name="billLeading"
                          id="billLeading"
                          placeholder="Bill Leading number"
                          autoComplete="off"
                          className="truncate"
                          required
                          readOnly
                        />
                      </FieldWrapper>
                    </InputGroup>

                    <InputGroup>
                      <Label htmlFor="importer">Importer</Label>
                      <FieldWrapper className="bg-gray-100">
                        <Field
                          type="text"
                          name="importer"
                          id="importer"
                          placeholder="Importer"
                          autoComplete="off"
                          className="truncate"
                          required
                          readOnly
                        />
                      </FieldWrapper>
                    </InputGroup>
                    <InputGroup>
                      <Label htmlFor="importerAddress">Importer Address</Label>
                      <FieldWrapper className="bg-gray-100">
                        <Field
                          type="text"
                          name="importerAddress"
                          id="importerAddress"
                          placeholder="Importer Address"
                          autoComplete="off"
                          className="truncate"
                          required
                          readOnly
                        />
                      </FieldWrapper>
                    </InputGroup>
                  </CombineInputGroup>
                  <CombineInputGroup>
                    <InputGroup>
                      <Label htmlFor="countryExport">Country of Export</Label>
                      <FieldWrapper className="!pl-0">
                        <CountrySelectorField
                          name="countryExport"
                          backendValue={formData?.countryExport}
                        />
                      </FieldWrapper>
                    </InputGroup>
                    <InputGroup>
                      <Label htmlFor="exporter">Exporter</Label>
                      <FieldWrapper>
                        <Field
                          type="text"
                          name="exporter"
                          id="exporter"
                          placeholder="Exporter"
                          autoComplete="off"
                          className="truncate"
                        />
                      </FieldWrapper>
                    </InputGroup>
                    <InputGroup>
                      <Label htmlFor="exporterAddress">Exporter Address</Label>
                      <FieldWrapper>
                        <Field
                          type="text"
                          name="exporterAddress"
                          id="exporterAddress"
                          placeholder="Exporter Address"
                          autoComplete="off"
                          className="truncate"
                        />
                      </FieldWrapper>
                    </InputGroup>
                  </CombineInputGroup>
                  <CombineInputGroup>
                    <InputGroup>
                      <Label htmlFor="portArrival">Port Arrival</Label>
                      <FieldWrapper>
                        <Field
                          type="text"
                          name="portArrival"
                          id="portArrival"
                          placeholder="Port Arrival"
                          autoComplete="off"
                          className="truncate"
                        />
                      </FieldWrapper>
                    </InputGroup>
                    <InputGroup>
                      <Label htmlFor="invoiceNo">Invoice No</Label>
                      <FieldWrapper>
                        <Field
                          type="text"
                          name="invoiceNo"
                          id="invoiceNo"
                          placeholder="Invoice No"
                          autoComplete="off"
                          className="truncate"
                          required
                        />
                      </FieldWrapper>
                    </InputGroup>

                    <InputGroup>
                      <Label htmlFor="invoiceDate">Invoice Date</Label>
                      <FieldWrapper>
                        <Field
                          type="date"
                          name="invoiceDate"
                          id="invoiceDate"
                          placeholder="Invoice Date"
                          autoComplete="off"
                          className="truncate"
                          required
                        />
                      </FieldWrapper>
                    </InputGroup>
                  </CombineInputGroup>
                  <CombineInputGroup>
                    <InputGroup>
                      <Label htmlFor="processOfGoods">Process of Goods</Label>
                      <FieldWrapper $select={true}>
                        <ProcessSelectorField
                          name="processOfGoods"
                          options={processOptions}
                          initialValue={formData?.processOfGoods}
                        />
                      </FieldWrapper>
                    </InputGroup>
                    <InputGroup>
                      <Label htmlFor="voucher">Receipt Voucher</Label>
                      <FieldWrapper>
                        <Field
                          type="text"
                          name="voucher"
                          id="voucher"
                          placeholder=""
                          autoComplete="off"
                          className="truncate"
                        />
                      </FieldWrapper>
                    </InputGroup>
                  </CombineInputGroup>
                  <CombineInputGroup>
                    <InputGroup>
                      <Label htmlFor="cocNo">COC No.</Label>
                      <FieldWrapper>
                        <Field
                          type="text"
                          name="cocNo"
                          id="cocNo"
                          placeholder=""
                          autoComplete="off"
                          className="truncate"
                        />
                      </FieldWrapper>
                    </InputGroup>
                    <InputGroup>
                      <Label htmlFor="exempted">Exempted</Label>
                      <FieldWrapper>
                        <Field
                          type="text"
                          name="exempted"
                          id="exempted"
                          placeholder=""
                          autoComplete="off"
                          className="truncate"
                        />
                      </FieldWrapper>
                    </InputGroup>
                  </CombineInputGroup>
                  <InputGroup>
                    <Label htmlFor="description">Good's Description</Label>
                    <FieldWrapper>
                      <Field
                        component="textarea"
                        row="10"
                        type="textarea"
                        name="description"
                        id="description"
                        autoComplete="off"
                      />
                    </FieldWrapper>
                  </InputGroup>
                  <InputGroup>
                    <Label htmlFor="remark">Remark</Label>
                    <FieldWrapper>
                      <Field
                        component="textarea"
                        row="10"
                        type="textarea"
                        name="remark"
                        id="remark"
                        autoComplete="off"
                        required
                      />
                    </FieldWrapper>
                  </InputGroup>

                  {rows.map((row, index) => (
                    <div
                      key={index}
                      className="border border-gray-100 px-3 pb-6 pt-2 bg-gray-50 rounded-md"
                    >
                      <CombineInputGridGroup>
                        <InputGroup>
                          <Label htmlFor={`containers[${index}].containerNo`}>
                            Container Number
                          </Label>
                          <FieldWrapper>
                            <Field
                              type="text"
                              name={`containers[${index}].containerNo`}
                              value={row.containerNo}
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  "containerNo",
                                  e.target.value
                                )
                              }
                            />
                          </FieldWrapper>
                        </InputGroup>

                        <InputGroup>
                          <Label htmlFor={`containers[${index}].size`}>
                            Size
                          </Label>
                          <FieldWrapper>
                            <Field
                              type="text"
                              name={`containers[${index}].size`}
                              value={row.size}
                              onChange={(e) =>
                                handleInputChange(index, "size", e.target.value)
                              }
                            />
                          </FieldWrapper>
                        </InputGroup>
                      </CombineInputGridGroup>

                      <CombineInputThreeGroup className="mt-2">
                        <InputGroup>
                          <Label htmlFor={`containers[${index}].package`}>
                            Package
                          </Label>
                          <FieldWrapper>
                            <Field
                              type="text"
                              name={`containers[${index}].package`}
                              value={row.package}
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  "package",
                                  e.target.value
                                )
                              }
                            />
                          </FieldWrapper>
                        </InputGroup>

                        <InputGroup>
                          <Label htmlFor={`containers[${index}].weight`}>
                            Weight
                          </Label>
                          <FieldWrapper>
                            <Field
                              type="number"
                              name={`containers[${index}].weight`}
                              value={row.weight}
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  "weight",
                                  e.target.value
                                )
                              }
                            />
                          </FieldWrapper>
                        </InputGroup>

                        <InputGroup>
                          <Label htmlFor={`containers[${index}].country`}>
                            Country
                          </Label>
                          <FieldWrapper>
                            <Field
                              type="text"
                              name={`containers[${index}].country`}
                              value={row.country}
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  "country",
                                  e.target.value
                                )
                              }
                            />
                          </FieldWrapper>
                        </InputGroup>
                      </CombineInputThreeGroup>

                      <InputGroup className="mt-2">
                        <Label htmlFor={`containers[${index}].itemDescription`}>
                          Item Description
                        </Label>
                        <FieldWrapper>
                          <Field
                            as="textarea"
                            name={`containers[${index}].itemDescription`}
                            value={row.itemDescription}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "itemDescription",
                                e.target.value
                              )
                            }
                          />
                        </FieldWrapper>
                      </InputGroup>
                    </div>
                  ))}
                  <div className="flex -mt-5">
                    <button
                      type="button"
                      onClick={() =>
                        setRows([
                          ...rows,
                          {
                            containerNo: "",
                            size: "",
                            weight: "",
                            package: "",
                            country: "",
                            itemDescription: "",
                          },
                        ])
                      }
                      className="text-blue-500 text-sm mr-4"
                    >
                      Add Another Sample
                    </button>
                    {/* {rows.length > 1 && (
                      <button
                        type="button"
                        onClick={() => {
                          if (rows.some((row) => !row.containerNo.trim())) {
                            removeEmptyRows();
                          } else {
                            const updatedRows = [...rows];
                            updatedRows.pop();
                            setRows(updatedRows);
                          }
                        }}
                        className="text-blue-500 text-sm"
                      >
                        {rows.some((row) => !row.containerNo.trim())
                          ? "Remove Empty Rows"
                          : "Remove Sample"}
                      </button>
                    )} */}
                    {/* {rows.length > 1 && (
                      <button
                        type="button"
                        onClick={() => {
                          const updatedRows = [...rows];
                          updatedRows.pop();
                          setRows(updatedRows);
                        }}
                        className="text-blue-500 text-sm"
                      >
                        Remove Sample
                      </button>
                    )} */}
                    {rows.some((row) => !row.containerNo.trim()) && (
                      <button
                        type="button"
                        onClick={removeEmptyRows}
                        className="text-blue-500 text-sm ml-4"
                      >
                        Remove Empty Rows
                      </button>
                    )}
                  </div>
                </Wrapper>
                <BtnWrapper>
                  <SubmitBtn type="submit" disabled={isLoadingReg}>
                    {isLoadingReg ? (
                      <Loading
                        color="white"
                        width={20}
                        height={20}
                        noPadding={true}
                      />
                    ) : (
                      t("Edit Certificate")
                    )}
                  </SubmitBtn>
                </BtnWrapper>
              </Form>
            </Formik>
          </Box>
        </BoxContainer>
      </Page>
    </>
  );
};

const Box = tw.div`border border-gray-100 py-4 md:py-8  px-4 md:px-20 shadow-md rounded-md`;
const Title = tw.h1`text-xl  text-gray-800 whitespace-nowrap   font-medium`;
const BtnWrapper = tw.div`flex justify-center items-center mt-8`;
const Wrapper = tw.div`
  grid  gap-6 my-6 px-1`;

export default EditGenerateCertificateForm;
