import React, { useEffect, useState } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import { PrevBtn, NextBtn, PaginationWrapper } from "../Styles/PageStyles";
import Images from "../../Images";
import tw from "tailwind-styled-components";
import { Link, redirectDocument } from "react-router-dom";
import moment from "moment";
import { PaginationLimit } from "../../Config";
import { HiEye } from "react-icons/hi";
import Config from "../../Config";
import { FaFileDownload, FaPlus, FaFileExport } from "react-icons/fa";
import { CiViewList } from "react-icons/ci";
import {
  MdOutlineDescription,
  MdDelete,
  MdModeEdit,
  MdPassword,
  MdSecurityUpdateWarning,
  MdViewCompactAlt,
} from "react-icons/md";
import {
  CollectedBadge,
  PendingBadge,
  ReadyBadge,
  SuccessBadge,
  InActiveBadge,
  RoleBadge,
  AutomaticBadge,
  ManualBadge,
} from "../Styles/InputStyles";
import { LuContainer } from "react-icons/lu";
import { useSelector } from "react-redux";
import { FiActivity } from "react-icons/fi";
const Table = ({
  ApiData,
  setSelectedData,
  setEditUser,
  setViewDetail,
  setAddSample,
  setEditSample,
  setUpdateSample,
  setDescription,
  setGeneratCertificate,
  setEditStatus,
}) => {
  const user = useSelector((state) => state.UserReducer.user);
  const [data, setData] = useState([
    {
      deviceName: "",
      extractionTime: "",
      processingTime: "",
      drops: "",
      action: "",
    },
  ]);

  const edit = (item) => {
    setEditUser(true);
    setSelectedData(item);
  };
  const updateStatus = (item) => {
    setEditStatus(true);
    setSelectedData(item);
  };
  const editSample = (item) => {
    setEditSample(true);
    setSelectedData(item);
  };
  const updateSample = (item) => {
    setUpdateSample(true);
    setSelectedData(item);
  };
  const add = (item) => {
    setAddSample(true);
    setSelectedData(item);
  };
  const desc = (item) => {
    setDescription(true);
    setSelectedData(item);
  };
  const generate = (item) => {
    setGeneratCertificate(true);
    setSelectedData(item);
  };

  const viewDetail = (item) => {
    setViewDetail(true);
    setSelectedData(item);
  };

  const getStatus = (status) => {
    if (status == Config.clientStatus.PENDING) {
      return <PendingBadge>Pending</PendingBadge>;
    } else if (status == Config.clientStatus.COLLECTED) {
      return <CollectedBadge>Collected</CollectedBadge>;
    } else if (status == Config.clientStatus.READY) {
      return <ReadyBadge>Ready</ReadyBadge>;
    } else if (status == Config.clientStatus.REJECTED) {
      return <InActiveBadge>Rejected</InActiveBadge>;
    } else if (status == Config.clientStatus.APPROVED) {
      return <SuccessBadge>Approved</SuccessBadge>;
    }
  };
  const getProcessType = (processType) => {
    if (processType == Config.processType.Automatic) {
      return <AutomaticBadge>Automatic</AutomaticBadge>;
    } else if (processType == Config.processType.Manually) {
      return <ManualBadge>Manually</ManualBadge>;
    }
  };
  const getGenerate = (certificatePath, id, blNumber, consent) => {
    // console.log("Consent:", consent);
    if (certificatePath) {
      return (
        <a href={certificatePath} target="_blank" className="text-blue-500">
          Click to View
        </a>
      );
    } else {
      if (
        (user.userType === Config.userType.DOCUMENTCOLLECTOR || user.userType === Config.userType.ADMIN) &&
        consent == Config.consentStatus.APPROVED
      ) {
        return (
          <Link
            className="text-blue-500"
            to={{
              pathname: `/generate-certificate/${id}/${blNumber}`,
            }}
          >
            Click to Generate
          </Link>
        );
      } else if (consent == Config.consentStatus.PENDING) {
        return <p className="text-yellow-500">Consent Pending</p>;
      } else {
        return <p>Not Generated</p>;
      }
    }
  };

  const ActionBtns = (item) => {
    return (
      <ActionBtnWrapper>
        <Btn title="View All Details" onClick={() => viewDetail(item)}>
          <HiEye className="text-gray-700" />
        </Btn>
        <Link to={`/container-list/${item.id}/${item.blNumber}`}>
          <Btn title="Container List">
            <LuContainer className="text-gray-700" />
          </Btn>
        </Link>
        {user.userType == Config.userType.DOCUMENTCOLLECTOR && (
          <>
            <Btn title="Upload Document" onClick={() => edit(item)}>
              <MdModeEdit className="text-gray-700" />
            </Btn>
          </>
        )}

        {(user.userType == Config.userType.DOCUMENTCOLLECTOR || user.userType == Config.userType.ADMIN) &&
          item.certificatePath && (
            <><Link to={`/edit-certificate/${item.id}/${item.blNumber}`}>
              <Btn title="Edit Release Certificate">
                <FaFileExport className="text-gray-700" />
              </Btn>
            </Link><Link to={`/activity-logs/${item.blNumber}`}>
                <Btn title="Activity Logs">
                  <FiActivity className="text-gray-700" />
                </Btn>
              </Link></>
          )}

        {user.userType == Config.userType.SAMPLECOLLECTOR &&
          item.certificatePath &&
          item.status != Config.clientStatus.READY &&
          item.isSampleAvailable != false && (
            <Link to={`/add-sample/${item.id}/${item.blNumber}`}>
              <Btn title="Add Sample">
                <FaPlus className="text-gray-700" />
              </Btn>
            </Link>
          )}

        {user.userType == Config.userType.SAMPLECOLLECTOR &&
          item.status == Config.clientStatus.COLLECTED &&
          item.certificatePath && (
            <Link to={`/view-sample-details/${item.id}/${item.blNumber}`}>
              <Btn title="View Sample Details">
                <MdViewCompactAlt className="text-gray-700" />
              </Btn>
            </Link>
          )}

        {(user.userType == Config.userType.QUALITYASSURER ||
          user.userType == Config.userType.ADMIN) &&
          (item.status == Config.clientStatus.COLLECTED ||
            item.status == Config.clientStatus.READY) &&
          item.certificatePath && (
            <Link to={`/update-sample-details/${item.id}/${item.blNumber}`}>
              <Btn
                title={
                  user.userType === Config.userType.QUALITYASSURER
                    ? "Update Sample Details"
                    : "View Sample Details"
                }
              >
                <MdSecurityUpdateWarning className="text-gray-700" />
              </Btn>
            </Link>
          )}
      </ActionBtnWrapper>
    );
  };

  useEffect(() => {
    // console.log("ApiData:", ApiData);
    if (ApiData) {
      const tempData = ApiData.map((item) => ({
        clientId: item.id || "N/A",
        companyName: item.companyName || "N/A",
        name: item.name || "N/A",
        mobile:
          item.mobile !== undefined && item.mobile !== null && item.mobile != 0
            ? item.mobile
            : "N/A",

        blNumber: item.blNumber || "N/A",
        certificatePath: item.certificatePath || "N/A",
        consent: item.consent || "N/A",

        ladingBillPath: item.ladingBillPath || "N/A",

        invoicePath11: item.invoicePath || "N/A",
        payReciptPath: item.payReciptPath || "N/A",
        status: getStatus(item.status),
        processType: getProcessType(item.processType),
        releaseCertificate: getGenerate(
          item.certificatePath,
          item.id,
          item.blNumber,
          item.consent,
          item
        ),

        creationDate: moment(item.creationDate).format("DD-MM-YYYY"),

        action: ActionBtns(item),
      }));
      // console.log("Mapped Data:", tempData);
      setData(tempData);
    }
  }, [ApiData]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Client Name",
        accessor: "name",
      },
      {
        Header: "Client Mobile",
        accessor: "mobile",
      },

      {
        Header: "Status",
        accessor: "status",
      },

      {
        Header: "Creation Date",
        accessor: "creationDate",
      },
      {
        Header: "B/L Number",
        accessor: "blNumber",
      },
      {
        Header: "Type",
        accessor: "processType",
      },

      {
        Header:
          user.userType == Config.userType.DOCUMENTCOLLECTOR ||
            user.userType == Config.userType.ADMIN
            ? "Release Certificate"
            : "Company Name",
        accessor:
          user.userType == Config.userType.DOCUMENTCOLLECTOR ||
            user.userType == Config.userType.ADMIN
            ? "releaseCertificate"
            : "companyName",
      },

      {
        Header: "Action",
        accessor: "action",
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    pageOptions,
    page,
    state: { pageIndex, pageSize },
    previousPage,
    nextPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
  } = useTable(
    {
      data,
      columns,
    },
    useSortBy,
    usePagination
  );

  useEffect(() => {
    setPageSize(PaginationLimit);
  }, []);

  return (
    <>
      <CustomTable {...getTableProps()}>
        <Thead>
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  <span>
                    {column.isSorted ? (
                      <img
                        src={Images.Arrow}
                        alt="down arrow"
                        className={`${column.isSortedDesc ? "-rotate-90" : "rotate-90"
                          } w-1.5 inline-block ml-1.5`}
                      />
                    ) : (
                      ""
                    )}
                  </span>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </CustomTable>

      <PaginationWrapper>
        <div className="px-2">
          Page{" "}
          <em>
            {pageIndex + 1} of {pageOptions.length}
          </em>
        </div>

        <div className="flex gap-1">
          <PrevBtn onClick={() => previousPage()} disabled={!canPreviousPage}>
            <img src={Images.Arrow} alt="arrow" className="w-2 opacity-75 " />
          </PrevBtn>
          <NextBtn onClick={() => nextPage()} disabled={!canNextPage}>
            <img
              src={Images.Arrow}
              alt="arrow"
              className="w-2 rotate-180 opacity-75"
            />
          </NextBtn>
        </div>
      </PaginationWrapper>
    </>
  );
};

const ActionBtnWrapper = tw.div`flex items-center space-x-3`;

const CustomTable = tw.table` w-full`;
const Thead = tw.thead`border-b`;
const Tbody = tw.tbody`border-b`;
const Tr = tw.tr`border-b rounded-md overflow-hidden `;
const Th = tw.th`text-left text-sm p-3 font-medium text-gray-500  `;
const Td = tw.td`p-3 text-sm`;

const Btn = tw.button`grid place-items-center border border-gray-300 w-8 h-8 rounded-full transform transition duration-200 hover:scale-110 hover:shadow-md`;

export default Table;
