import React, { useState, useEffect } from "react";
import tw from "tailwind-styled-components";
import Config from "../../Config";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Field, Formik, Form } from "formik";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "react-query";
import Navbar from "../../Components/Navbar";
import Loading from "react-loading";
import {
  InputGroup,
  FieldWrapper,
  Label,
  CombineInputThreeGroup,
  CombineInputGridGroup,
  SubmitBtn,
  CombineInputGroup,
} from "../../Components/Styles/InputStyles";
import Select from "react-select";
import { BoxContainer, Page } from "../../Components/Styles/PageStyles";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CountrySelector from "../../Components/DocumentCollectionUser/CountrySelector";
import moment from "moment";
import { useLocation } from "react-router-dom";

const ManuallyForm = () => {
  const user = useSelector((state) => state.UserReducer.user);
  const location = useLocation();
  const { name, address, companyName } = location.state || {};
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { blNumber } = useParams();

  const InitialValue = {
    containerNo: "",
    blNumber: blNumber,
    size: "",
    package: "",
    itemDescription: "",
    weight: "",
    country: "",
    invoiceDate: moment().format("YYYY-MM-DD"),
    remark: "CONTAINERS TO BE RELEASED FOR INSPECTION , NOT FOR EXIT",
    importer: companyName,
    countryExport: "selectedCountry.label",
    importerAddress: address,
    invoiceNo: "",
    voucher: "",
    exporter: "",
    exporterAddress: "",
    portArrival: "",
    description: "",
    exempted: "",
    cocNo: "",
  };

  const [isProductionFetched, setIsProductionFetched] = useState(false);
  const [isAddMoreAvailable, setIsAddMoreAvailable] = useState(true);
  const [selectedGoodProcess, setSelectedGoodProcess] = useState({
    label: "Please Select",
    value: "NA",
  });
  const [selectedCountry, setSelectedCountry] = useState({
    label: "Somalia",
    value: "SO",
  });

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
  };

  const [rows, setRows] = useState([
    {
      containerNo: "",
      size: "",
      weight: "",
      package: "",
      country: "",
      itemDescription: "",
    },
  ]);

  const addRow = () => {
    setRows([
      ...rows,
      {
        containerNo: "",
        size: "",
        weight: "",
        package: "",
        country: "",
        itemDescription: "",
      },
    ]);
  };

  const removeRow = () => {
    if (rows.length > 1) {
      const updatedRows = [...rows];
      updatedRows.pop();
      setRows(updatedRows);
    }
  };

  const handleInputChange = (index, fieldName, value) => {
    const updatedRows = [...rows];
    updatedRows[index][fieldName] = value;
    setRows(updatedRows);
  };

  //------- Add Single Entry -------
  const AddUserFunction = async (values) => {
    console.log(values, "Anuj");
    const body = new FormData();
    const containers = values.containers.map((container) => ({
      ...container,
    }));
    body.append("blNumber", blNumber);
    body.append("containers", JSON.stringify(containers) || "");

    return await axios.post(`${Config.apiUrl}/add-container-details`, body, {
      headers: {
        authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const onAddUserSuccess = (data, values) => {
    if (data?.data?.status == "OK") {
      // toast.success(data?.data?.msg || "Success");
      const clientId = data?.data?.clientId;

      const updatedValues = {
        ...values,
        countryExport: selectedCountry ? selectedCountry.label : "",
        id: clientId,
      };
      generateCertificate(updatedValues);
    } else {
      toast.error(data?.data?.msg);
    }
  };

  const onAddUserError = (data) => {
    toast.error(data?.response?.data?.msg || "An Error Occurred");
  };

  const { isLoading: AddSubAgentLoading, mutate: postUser } = useMutation(
    AddUserFunction,
    {
      onSuccess: onAddUserSuccess,
      onError: onAddUserError,
    }
  );

  const AddGenerateFunction = async (values) => {
    console.log(values);
    const body = new FormData();
    body.append("userId", user.userId);
    body.append("billLeading", values.blNumber);
    body.append("invoiceNo", values.invoiceNo);
    body.append("invoiceDate", values.invoiceDate);
    body.append("remark", values.remark);
    body.append("description", values.description);
    body.append("voucher", values.voucher);
    body.append("processOfGoods", selectedGoodProcess.value);
    body.append("importer", values.importer);
    body.append("importerAddress", values.importerAddress);
    body.append("countryExport", values.countryExport);
    body.append("exporter", values.exporter);
    body.append("exporterAddress", values.exporterAddress);
    body.append("portArrival", values.portArrival);
    body.append("id", values.id);
    body.append("cocNo", values.cocNo);
    body.append("exempted", values.exempted);

    return await axios.post(`${Config.apiUrl}/generate-certificate`, body, {
      headers: {
        Authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const onGenerateSuccess = (res) => {
    if (res?.data?.status == "OK") {
      toast.success(res?.data?.msg || "Success");
      if (res?.data?.certificate) {
        const certificateUrl = res.data.certificate;
        window.open(certificateUrl, "_blank");
      }
      navigate("/client-list");
    } else {
      toast.error(res?.data?.msg || "Error");
    }
  };

  const onGenerateError = (res) => {
    toast.error(res?.response?.data?.msg || "An Error Occurred");
  };

  const { isLoading: isLoadingReg, mutate: generateCertificate } = useMutation(
    AddGenerateFunction,
    {
      onSuccess: onGenerateSuccess,
      onError: onGenerateError,
    }
  );

  const SubmitHandler = (values) => {
    const countryExportCode = selectedCountry ? selectedCountry.value : "";
    if (!blNumber) {
      toast.error("Please enter your B/L number ");
      return;
    }
    if (selectedGoodProcess.value == "NA") {
      toast.error("Please select the process of goods");
      return;
    }
    const updatedValues = {
      ...values,
      countryExport: selectedCountry ? selectedCountry.label : "",
      containers: rows,
    };
    postUser(updatedValues, countryExportCode);
  };

  const processOptions = [
    { label: "Lab Test", value: "lab" },
    { label: "Inspection", value: "inspection" },
    { label: "Others", value: "others" },
  ];

  const handleProcessChange = (selectedOption) => {
    setSelectedGoodProcess(selectedOption);
  };

  return (
    <>
      <Navbar />
      <Page>
        <BoxContainer>
          <Box>
            <div className="flex w-full justify-center items-center">
              <div>
                <Title>Add Container Details</Title>
                <Underline />
              </div>
            </div>

            <Formik
              initialValues={InitialValue}
              onSubmit={SubmitHandler}
              enableReinitialize
            >
              <>
                {/* {({ values, handleChange }) => ( */}
                <Form>
                  <Wrapper>
                    <CombineInputGroup>
                      <InputGroup>
                        <Label htmlFor="blNumber">B/L Number</Label>
                        <FieldWrapper className="bg-gray-100">
                          <Field
                            type="text"
                            name="blNumber"
                            id="blNumber"
                            autoComplete="off"
                            className="truncate "
                            required
                            readOnly
                          />
                        </FieldWrapper>
                      </InputGroup>
                      <InputGroup>
                        <Label htmlFor="importer">Importer</Label>
                        <FieldWrapper className="bg-gray-100">
                          <Field
                            type="text"
                            name="importer"
                            id="importer"
                            placeholder="Importer"
                            autoComplete="off"
                            className="truncate"
                            required
                            readOnly
                          />
                        </FieldWrapper>
                      </InputGroup>
                      <InputGroup>
                        <Label htmlFor="importerAddress">
                          Importer Address
                        </Label>
                        <FieldWrapper className="bg-gray-100">
                          <Field
                            type="text"
                            name="importerAddress"
                            id="importerAddress"
                            placeholder="Importer Address"
                            autoComplete="off"
                            className="truncate"
                            required
                            readOnly
                          />
                        </FieldWrapper>
                      </InputGroup>
                    </CombineInputGroup>
                    <CombineInputGroup>
                      <InputGroup>
                        <Label htmlFor="countryExport">Country of Export</Label>
                        <FieldWrapper className="!pl-0">
                          <CountrySelector onChange={handleCountryChange} />
                        </FieldWrapper>
                      </InputGroup>
                      <InputGroup>
                        <Label htmlFor="exporter">Exporter</Label>
                        <FieldWrapper>
                          <Field
                            type="text"
                            name="exporter"
                            id="exporter"
                            placeholder="Exporter"
                            // value={values.exporter}
                            // onChange={handleChange}
                            autoComplete="off"
                            className="truncate"
                            // required
                          />
                        </FieldWrapper>
                      </InputGroup>
                      <InputGroup>
                        <Label htmlFor="exporterAddress">
                          Exporter Address
                        </Label>
                        <FieldWrapper>
                          <Field
                            type="text"
                            name="exporterAddress"
                            id="exporterAddress"
                            // value={values.exporterAddress}
                            // onChange={handleChange}
                            placeholder="Exporter Address"
                            autoComplete="off"
                            className="truncate"
                            // required
                          />
                        </FieldWrapper>
                      </InputGroup>
                    </CombineInputGroup>
                    <CombineInputGroup>
                      <InputGroup>
                        <Label htmlFor="portArrival">Port Arrival</Label>
                        <FieldWrapper>
                          <Field
                            type="text"
                            name="portArrival"
                            id="portArrival"
                            placeholder="Port Arrival"
                            // value={values.portArrival}
                            // onChange={handleChange}
                            autoComplete="off"
                            className="truncate"
                            // required
                          />
                        </FieldWrapper>
                      </InputGroup>
                      <InputGroup>
                        <Label htmlFor="invoiceNo">Invoice No</Label>
                        <FieldWrapper>
                          <Field
                            type="text"
                            name="invoiceNo"
                            id="invoiceNo"
                            placeholder="Invoice No"
                            // value={values.invoiceNo}
                            // onChange={handleChange}
                            autoComplete="off"
                            className="truncate"
                            required
                          />
                        </FieldWrapper>
                      </InputGroup>

                      <InputGroup>
                        <Label htmlFor="invoiceDate">Invoice Date</Label>
                        <FieldWrapper>
                          <Field
                            type="date"
                            name="invoiceDate"
                            id="invoiceDate"
                            placeholder="Invoice Date"
                            autoComplete="off"
                            className="truncate"
                            required
                          />
                        </FieldWrapper>
                      </InputGroup>
                    </CombineInputGroup>
                    <CombineInputGroup>
                      <InputGroup>
                        <Label htmlFor="exporterAddress">
                          Process of Goods
                        </Label>
                        <FieldWrapper $select={true}>
                          <Select
                            value={selectedGoodProcess}
                            onChange={handleProcessChange}
                            options={processOptions}
                            placeholder="Select a country"
                            className="w-full"
                          />
                        </FieldWrapper>
                      </InputGroup>
                      <InputGroup>
                        <Label htmlFor="exporter">Receipt Voucher</Label>
                        <FieldWrapper>
                          <Field
                            type="text"
                            name="voucher"
                            id="voucher"
                            placeholder=""
                            autoComplete="off"
                            className="truncate"
                          />
                        </FieldWrapper>
                      </InputGroup>
                    </CombineInputGroup>
                    <CombineInputGroup>
                      <InputGroup>
                        <Label htmlFor="cocNo">COC No.</Label>
                        <FieldWrapper>
                          <Field
                            type="text"
                            name="cocNo"
                            id="cocNo"
                            placeholder=""
                            autoComplete="off"
                            className="truncate"
                          />
                        </FieldWrapper>
                      </InputGroup>
                      <InputGroup>
                        <Label htmlFor="exempted">Exempted</Label>
                        <FieldWrapper>
                          <Field
                            type="text"
                            name="exempted"
                            id="exempted"
                            placeholder=""
                            autoComplete="off"
                            className="truncate"
                          />
                        </FieldWrapper>
                      </InputGroup>
                    </CombineInputGroup>
                    <InputGroup>
                      <Label htmlFor="description">Good's Description</Label>
                      <FieldWrapper>
                        <Field
                          component="textarea"
                          row="10"
                          type="textarea"
                          name="description"
                          id="description"
                          autoComplete="off"
                          // value={values.description}
                          // onChange={handleChange}

                          // required
                        />
                      </FieldWrapper>
                    </InputGroup>
                    <InputGroup>
                      <Label htmlFor="remark">Remark</Label>
                      <FieldWrapper>
                        <Field
                          component="textarea"
                          row="10"
                          type="textarea"
                          name="remark"
                          id="remark"
                          autoComplete="off"
                          required
                        />
                      </FieldWrapper>
                    </InputGroup>
                    {rows.map((row, index) => (
                      <div className="border border-gray-100 px-3 pb-6 pt-2  bg-gray-50 rounded-md ">
                        <CombineInputGridGroup>
                          <InputGroup>
                            <Label htmlFor={`containerNo${index}`}>
                              Container Number
                            </Label>
                            <FieldWrapper>
                              <Field
                                type="text"
                                name={`containerNo${index}`}
                                id={`containerNo${index}`}
                                autoComplete="off"
                                className="truncate"
                                required
                                value={row.containerNo}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "containerNo",
                                    e.target.value
                                  )
                                }
                              />
                            </FieldWrapper>
                          </InputGroup>

                          <InputGroup>
                            <Label htmlFor={`size${index}`}>Size</Label>
                            <FieldWrapper>
                              <Field
                                type="text"
                                name={`size${index}`}
                                id={`size${index}`}
                                autoComplete="off"
                                className="truncate "
                                // required
                                value={row.size}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "size",
                                    e.target.value
                                  )
                                }
                              />
                            </FieldWrapper>
                          </InputGroup>
                        </CombineInputGridGroup>
                        <CombineInputThreeGroup className="mt-2">
                          <InputGroup>
                            <Label htmlFor={`package${index}`}>Package</Label>
                            <FieldWrapper>
                              <Field
                                type="text"
                                name={`package${index}`}
                                id={`package${index}`}
                                autoComplete="off"
                                className="truncate"
                                value={row.package}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "package",
                                    e.target.value
                                  )
                                }
                                // required
                              />
                            </FieldWrapper>
                          </InputGroup>

                          <InputGroup>
                            <Label htmlFor={`weight${index}`}>Weight</Label>
                            <FieldWrapper>
                              <Field
                                type="number"
                                name={`weight${index}`}
                                id={`weight${index}`}
                                autoComplete="off"
                                className="truncate "
                                value={row.weight}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "weight",
                                    e.target.value
                                  )
                                }
                                // required
                              />
                            </FieldWrapper>
                          </InputGroup>

                          <InputGroup>
                            <Label htmlFor={`country${index}`}>Country</Label>
                            <FieldWrapper>
                              <Field
                                type="text"
                                name={`country${index}`}
                                id={`country${index}`}
                                autoComplete="off"
                                className="truncate"
                                value={row.country}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "country",
                                    e.target.value
                                  )
                                }
                                // required
                              />
                            </FieldWrapper>
                          </InputGroup>
                        </CombineInputThreeGroup>

                        <InputGroup className="mt-2">
                          <Label htmlFor={`itemDescription${index}`}>
                            Item Description
                          </Label>
                          <FieldWrapper>
                            <Field
                              component="textarea"
                              row="10"
                              type="textarea"
                              name={`itemDescription${index}`}
                              id={`itemDescription${index}`}
                              autoComplete="off"
                              value={row.itemDescription}
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  "itemDescription",
                                  e.target.value
                                )
                              }
                              // required
                            />
                          </FieldWrapper>
                        </InputGroup>
                      </div>
                    ))}

                    <div className="flex -mt-5">
                      {isAddMoreAvailable && (
                        <button
                          type="button"
                          onClick={addRow}
                          className="text-blue-500 text-sm mr-4"
                        >
                          Add Another Sample
                        </button>
                      )}
                      {rows.length > 1 && (
                        <button
                          type="button"
                          onClick={() => removeRow()}
                          className="text-blue-500 text-sm "
                        >
                          Remove Sample
                        </button>
                      )}
                    </div>
                  </Wrapper>
                  <BtnWrapper>
                    <SubmitBtn type="submit" disabled={AddSubAgentLoading}>
                      {" "}
                      {AddSubAgentLoading ? (
                        <Loading
                          color="white"
                          width={20}
                          height={20}
                          noPadding={true}
                        />
                      ) : (
                        t("Submit")
                      )}
                    </SubmitBtn>
                  </BtnWrapper>
                </Form>
                {/* )} */}
              </>
            </Formik>
          </Box>
        </BoxContainer>
      </Page>
    </>
  );
};

const TextWrapper = tw.div`h-full w-full flex justify-center`;
const Box = tw.div`border border-gray-100 py-4 md:py-8 px-4 md:px-20 shadow-md rounded-md`;
const Title = tw.h1`text-xl text-gray-800 whitespace-nowrap font-medium`;
const UpdateBtn = tw.button`text-sm w-32 md:w-60 h-12 grid place-items-center disabled:opacity-50 disabled:cursor-not-allowed md:text-base bg-custom-green hover:bg-green-700 text-white rounded-md shadow-md md:mx-44`;
const BtnWrapper = tw.div`flex justify-center items-center mt-8`;
const Wrapper = tw.div`grid gap-6 my-6 px-1`;
const Underline = tw.div`w-20 mx-auto h-0.5 bg-gray-700 mt-1.5 rounded-full`;

export default ManuallyForm;
