import React, { useEffect, useState } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import { PrevBtn, NextBtn, PaginationWrapper } from "../Styles/PageStyles";
import Images from "../../Images";
import { PaginationLimit } from "../../Config";
import tw from "tailwind-styled-components";
import moment from "moment";


const ActivityLogTable = ({ ApiData }) => {
    const [data, setData] = useState([]);
    const isFieldChanged = (currentValue, previousValue) => {
        return currentValue !== previousValue;
    };

    useEffect(() => {
        if (ApiData?.length) {
            const sortedData = ApiData.sort((a, b) => b.id - a.id).map((item, index, arr) => {
                const previousItem = arr[index + 1] || {};
                return {
                    id: item.id || "N/A",
                    blNumber: item.blNumber || "N/A",
                    clientId: item.clientId || "N/A",
                    consent: item.consent || "N/A",
                    countryExport: item.countryExport || "N/A",
                    description: item.description || "N/A",
                    exporterName: item.exporterName || "N/A",
                    exporterAddress: item.exporterAddress || "N/A",
                    invoiceDate: item.invoiceDate ? moment(item.invoiceDate).format("DD-MM-YYYY") : "N/A",
                    updatedDate: item.updated_at ? moment(item.updated_at).format("DD-MM-YYYY hh:mm:ss") : "N/A",
                    invoiceNumber: item.invoiceNumber || "N/A",
                    portArrival: item.portArrival || "N/A",
                    processOfGoods: item.processOfGoods || "N/A",
                    remarks: item.remarks || "N/A",
                    voucher: item.voucher || "N/A",
                    previousData: previousItem,
                    updatedBy: item.updatedBy || "N/A",
                    cocNo: item.cocNo || "N/A",
                    exempted: item.exempted || "N/A",
                    createdBy: item.createdBy || "N/A"

                };
            });
            setData(sortedData);
        }
    }, [ApiData]);

    const columns = React.useMemo(
        () => [
            {
                Header: "Created By",
                accessor: "createdBy",
                Cell: ({ row }) => {
                    const isLastEntry = row.index === ApiData.length - 1;
                    const isChanged = !isLastEntry && isFieldChanged(
                        row.original.createdBy,
                        row.original.previousData?.createdBy
                    );
                    return (
                        <div className={`w-32 break-words ${isChanged ? "font-medium" : "text- gray-700"}`}>
                            {row.original.createdBy}
                        </div>
                    );
                },
            },
            {
                Header: "Updated By",
                accessor: "updatedBy",
                Cell: ({ row }) => {
                    const isLastEntry = row.index === ApiData.length - 1;
                    const isChanged = !isLastEntry && isFieldChanged(
                        row.original.updatedBy,
                        row.original.previousData?.updatedBy
                    );
                    return (
                        <div className={`w-32 break-words ${isChanged ? "font-medium" : "text- gray-700"}`}>
                            {row.original.updatedBy}
                        </div>
                    );
                },
            },
            {
                Header: "Exporter Name",
                accessor: "exporterName",
                Cell: ({ row }) => {
                    const isLastEntry = row.index === ApiData.length - 1;
                    const isChanged = !isLastEntry && isFieldChanged(
                        row.original.exporterName,
                        row.original.previousData?.exporterName
                    );
                    return (
                        <div className={`w-28 break-words ${isChanged ? "font-medium" : "text- gray-700"}`}>
                            {row.original.exporterName}
                        </div>
                    );
                },
            },
            {
                Header: "Exporter Address",
                accessor: "exporterAddress",
                Cell: ({ row }) => {
                    const isLastEntry = row.index === ApiData.length - 1;
                    const isChanged = !isLastEntry && isFieldChanged(
                        row.original.exporterAddress,
                        row.original.previousData?.exporterAddress
                    );
                    return (
                        <div className={`w-28 break-words ${isChanged ? "font-medium" : "text- gray-700"}`}>
                            {row.original.exporterAddress}
                        </div>
                    );
                },
            },
            {
                Header: "Country Export",
                accessor: "countryExport",
                Cell: ({ row }) => {
                    const isLastEntry = row.index === ApiData.length - 1;
                    const isChanged = !isLastEntry && isFieldChanged(
                        row.original.countryExport,
                        row.original.previousData?.countryExport
                    );
                    return (
                        <div className={`w-28 break-words ${isChanged ? "font-medium" : "text- gray-700"}`}>
                            {row.original.countryExport}
                        </div>
                    );
                },
            },

            {
                Header: "Invoice Date",
                accessor: "invoiceDate",
                Cell: ({ row }) => {
                    const isLastEntry = row.index === ApiData.length - 1;
                    const isChanged = !isLastEntry && isFieldChanged(
                        row.original.invoiceDate,
                        row.original.previousData?.invoiceDate
                    );
                    return (
                        <div className={`w-28 break-words ${isChanged ? "font-medium" : "text- gray-700"}`}>
                            {row.original.invoiceDate}
                        </div>
                    );
                },
            },
            {
                Header: "Invoice Number",
                accessor: "invoiceNumber",
                Cell: ({ row }) => {
                    const isLastEntry = row.index === ApiData.length - 1;
                    const isChanged = !isLastEntry && isFieldChanged(
                        row.original.invoiceNumber,
                        row.original.previousData?.invoiceNumber
                    );
                    return (
                        <div className={`w-28 break-words ${isChanged ? "font-medium" : "text- gray-700"}`}>
                            {row.original.invoiceNumber}
                        </div>
                    );
                },
            },
            {
                Header: "Port Arrival",
                accessor: "portArrival",
                Cell: ({ row }) => {
                    const isLastEntry = row.index === ApiData.length - 1;
                    const isChanged = !isLastEntry && isFieldChanged(
                        row.original.portArrival,
                        row.original.previousData?.portArrival
                    );
                    return (
                        <div className={`w-28 break-words ${isChanged ? "font-medium" : "text- gray-700"}`}>
                            {row.original.portArrival}
                        </div>
                    );
                },
            },
            {
                Header: "Process Of Goods",
                accessor: "processOfGoods",
                Cell: ({ row }) => {
                    const isLastEntry = row.index === ApiData.length - 1;
                    const isChanged = !isLastEntry && isFieldChanged(
                        row.original.processOfGoods,
                        row.original.previousData?.processOfGoods
                    );
                    return (
                        <div className={`w-32 break-words ${isChanged ? "font-medium" : "text- gray-700"}`}>
                            {row.original.processOfGoods}
                        </div>
                    );
                },
            },
            {
                Header: "Voucher",
                accessor: "voucher",
                Cell: ({ row }) => {
                    const isLastEntry = row.index === ApiData.length - 1;
                    const isChanged = !isLastEntry && isFieldChanged(
                        row.original.voucher,
                        row.original.previousData?.voucher
                    );
                    return (
                        <div className={`w-28 break-words ${isChanged ? "font-medium" : "text- gray-700"}`}>
                            {row.original.voucher}
                        </div>
                    );
                },
            },
            {
                Header: "COC No.",
                accessor: "cocNo",
                Cell: ({ row }) => {
                    const isLastEntry = row.index === ApiData.length - 1;
                    const isChanged = !isLastEntry && isFieldChanged(
                        row.original.cocNo,
                        row.original.previousData?.cocNo
                    );
                    return (
                        <div className={`w-28 break-words ${isChanged ? "font-medium" : "text- gray-700"}`}>
                            {row.original.cocNo}
                        </div>
                    );
                },
            },
            {
                Header: "Exempted",
                accessor: "exempted",
                Cell: ({ row }) => {
                    const isLastEntry = row.index === ApiData.length - 1;
                    const isChanged = !isLastEntry && isFieldChanged(
                        row.original.exempted,
                        row.original.previousData?.exempted
                    );
                    return (
                        <div className={`w-28 break-words ${isChanged ? "font-medium" : "text- gray-700"}`}>
                            {row.original.exempted}
                        </div>
                    );
                },
            },
            {
                Header: "Remarks",
                accessor: "remarks",
                Cell: ({ row }) => {
                    const isLastEntry = row.index === ApiData.length - 1;
                    const isChanged = !isLastEntry && isFieldChanged(
                        row.original.remarks,
                        row.original.previousData?.remarks
                    );
                    return (
                        <div className={`w-40 break-words ${isChanged ? "font-medium" : "text- gray-700"}`}>
                            {row.original.remarks}
                        </div>
                    );
                },
            },

            {
                Header: "Description",
                accessor: "description",
                Cell: ({ row }) => {
                    const isLastEntry = row.index === ApiData.length - 1;
                    const isChanged = !isLastEntry && isFieldChanged(
                        row.original.description,
                        row.original.previousData?.description
                    );
                    return (
                        <div className={`w-40 break-words ${isChanged ? "font-medium" : "text- gray-700"}`}>
                            {row.original.description}
                        </div>
                    );
                },
            },
            {
                Header: "Updated At",
                accessor: "updatedDate",
                Cell: ({ row }) => {
                    const isLastEntry = row.index === ApiData.length - 1;
                    const isChanged = !isLastEntry && isFieldChanged(
                        row.original.updatedDate,
                        row.original.previousData?.updatedDate
                    );
                    return (
                        <div className={`w-28 break-words ${isChanged ? "font-medium" : "text- gray-700"}`}>
                            {row.original.updatedDate}
                        </div>
                    );
                },
            },
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        pageOptions,
        page,
        state: { pageIndex, pageSize },
        previousPage,
        nextPage,
        setPageSize,
        canPreviousPage,
        canNextPage,
    } = useTable(
        {
            data,
            columns,
        },
        useSortBy,
        usePagination
    );

    useEffect(() => {
        setPageSize(PaginationLimit);
    }, []);

    return (
        <>
            <CustomTable {...getTableProps()}>
                <Thead>
                    {headerGroups.map((headerGroup) => (
                        <Tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render("Header")}
                                    <span>
                                        {column.isSorted ? (
                                            <img
                                                src={Images.Arrow}
                                                alt="down arrow"
                                                className={`${column.isSortedDesc ? "-rotate-90" : "rotate-90"
                                                    } w-1.5 inline-block ml-1.5`}
                                            />
                                        ) : (
                                            ""
                                        )}
                                    </span>
                                </Th>
                            ))}
                        </Tr>
                    ))}
                </Thead>
                <Tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                        prepareRow(row);
                        return (
                            <Tr {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                    return (
                                        <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                                    );
                                })}
                            </Tr>
                        );
                    })}
                </Tbody>
            </CustomTable>

            <PaginationWrapper>
                <div className="px-2">
                    Page{" "}
                    <em>
                        {pageIndex + 1} of {pageOptions.length}
                    </em>
                </div>

                <div className="flex gap-1">
                    <PrevBtn onClick={() => previousPage()} disabled={!canPreviousPage}>
                        <img src={Images.Arrow} alt="arrow" className="w-2 opacity-75 " />
                    </PrevBtn>
                    <NextBtn onClick={() => nextPage()} disabled={!canNextPage}>
                        <img
                            src={Images.Arrow}
                            alt="arrow"
                            className="w-2 rotate-180 opacity-75"
                        />
                    </NextBtn>
                </div>
            </PaginationWrapper>
        </>
    );
};

const CustomTable = tw.table` w-full`;
const Thead = tw.thead`border-b`;
const Tbody = tw.tbody`border-b`;
const Tr = tw.tr`border-b rounded-md overflow-hidden `;
const Th = tw.th`text-left text-sm p-3 font-medium text-gray-500  `;
const Td = tw.td`p-3 text-sm`;

export default ActivityLogTable;