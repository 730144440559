import React, { useEffect, useState, useRef } from "react";
import Images from "../Images";
import tw from "tailwind-styled-components";
import Config from "../Config";
import axios from "axios";
import { useDispatch } from "react-redux";
import { Save_User, Save_Games_Data } from "../Redux/actions";
import { useNavigate } from "react-router-dom";
import { Field, Formik, Form } from "formik";
import { toast } from "react-toastify";
import { useMutation } from "react-query";
import Card from "../Components/Card/LoginCard";
import { FieldWrapper, Label } from "../Components/Styles/InputStyles";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const OtpPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const InitialValue = {
    otp: "",
    phone: "",
  };

  //OTP Post Function
  const OTPPostFunction = async (values) => {
    const body = new FormData();
    // body.append("email", email);
    body.append(
      "otp",
      values.otp1 + "" + values.otp2 + "" + values.otp3 + "" + values.otp4
    );
    body.append("phone", location.state?.phone);

    return await axios.post(`${Config.apiUrl}/verify-otp`, body, {
      headers: {
        authorization: Config.AxiosConfig.headers.authorization,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const onOTPSuccess = (res) => {
    console.log(res.data);
    // if (res?.data?.status == "OK") {
    //   dispatch(Save_User(res.data.data));
    //   toast.success(res?.data?.msg || "Success!!");
    // } else toast.error(res?.data?.msg || "Error");
  };

  const onOTPError = (res) => {
    toast.error(res?.response?.data?.msg || "An Error Occured");
  };

  const { isLoading: isLoadingOTP, mutate: postOTP } = useMutation(
    OTPPostFunction,
    {
      onSuccess: onOTPSuccess,
      onError: onOTPError,
    }
  );
  //------- Send OTP Request -------

  const SubmitHandler = (values) => {
    postOTP({ ...values });
    navigate("/");
  };

  const handleLogin = () => {};

  //------- Send Login Request -------

  // const SubmitHandler = (values) => {
  // if (values.otp) {
  //   return toast.error("OTP matched successfully");
  // } else {
  //   return toast.error("Please enter correct otp!!!");
  // }
  //   postLogin({ ...values });
  // };

  return (
    <Login
      style={{
        backgroundImage: `url(${Images.LoginImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center right",
      }}
    >
      <Card>
        <TextWrapperComp
          InitialValue={InitialValue}
          SubmitHandler={SubmitHandler}
          t={t}
        />
      </Card>
    </Login>
  );
};

const TextWrapperComp = ({ InitialValue, SubmitHandler, t }) => {
  const inputRefs = [useRef(), useRef(), useRef(), useRef()];

  const handleInputChange = (index, value) => {
    // Move focus to the next input field
    if (index < inputRefs.length - 1 && value !== "") {
      inputRefs[index + 1].current.focus();
    }
  };

  return (
    <TextWrapper>
      <Container>
        <Center>
          <Title>{t("OTP Verification")}</Title>
          <SubTitle>
            {t("Please enter the code sent to your mobile number")}
          </SubTitle>
          <Formik initialValues={InitialValue} onSubmit={SubmitHandler}>
            {(formikProps) => (
              <>
                <Form className="w-full">
                  <FormContainer>
                    {Array.from({ length: 4 }).map((_, index) => (
                      <InputGroup key={index}>
                        <Label htmlFor={`otp${index + 1}`}>{("")}</Label>
                        <FieldWrapper>
                          <Field
                            name={`otp${index + 1}`}
                            id={`otp${index + 1}`}
                            className="mx-3"
                            autoComplete="off"
                            maxLength={1}
                            onChange={(e) => {
                              formikProps.handleChange(e);
                              handleInputChange(index, e.target.value);
                            }}
                            innerRef={inputRefs[index]}
                          />
                        </FieldWrapper>
                      </InputGroup>
                    ))}
                  </FormContainer>

                  <div className="mt-16 flex">
                    <SubmitBtn type="submit">{t("Submit")}</SubmitBtn>
                  </div>
                </Form>
              </>
            )}
          </Formik>
        </Center>
      </Container>
    </TextWrapper>
  );
};

const Login = tw.section`h-screen flex  bg-white justify-center items-center`;
const Container = tw.div`flex flex-col h-full w-full justify-center items-start   `;
const TextWrapper = tw.div`h-full relative md:pl-8 -mt-8 w-64 md:w-auto pr-4 md:pr-0`;
const Title = tw.h1`text-xl sm:text-2xl font-black tracking-wider -mt-8`;
const SubTitle = tw.h3` sm:mt-2 tracking-wider  text-gray-500 text-sm `;
const Center = tw.div`h-full w-11/12  py-10 pt-20 mb-5  flex flex-col   `;
const SubmitBtn = tw.button`
text-sm -mt-8  w-80 h-12 grid place-items-center disabled:opacity-50 disabled:cursor-not-allowed   md:text-base bg-black hover:bg-gray-900 text-white rounded-md shadow-md`;
const FormContainer = tw.div` grid grid-cols-4 max-w-sm  mt-5 w-full gap-5 md:gap-5`;
const InputGroup = tw.div`w-14 flex `;
export default OtpPage;
