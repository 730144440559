import { useState } from "react";
import tw from "tailwind-styled-components";
import Loading from "../../Components/Loading";
import {
  Underline,
  BoxContainer,
  Page,
} from "../../Components/Styles/PageStyles";
import Navbar from "../../Components/Navbar";
import { useSelector } from "react-redux";
import Config from "../../Config";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useQuery } from "react-query";
import NotFoundModel from "../../Components/NotFoundModel";
import ActivityLogTable from "../../Components/DocumentCollectionUser/ActivityLogTable";

const ActivityLogs = () => {
  const { blNumber } = useParams();
  const user = useSelector((state) => state.UserReducer.user);
  const [isProductionFetched, setIsProductionFetched] = useState(false);
  const [formData, setFormData] = useState(null);
  const [selectedData, setSelectedData] = useState("");
  const EditProduct = async () =>
    await axios.get(`${Config.apiUrl}/activity-logs/${blNumber}`, {
      headers: {
        Authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });

  const { data, isLoading, error, isRefetching } = useQuery(
    "get-production",
    EditProduct,
    {
      enabled: !isProductionFetched,
      onSuccess: (data) => {
        if (data?.data?.data) {
          setIsProductionFetched(true);
          setFormData(data.data?.data);
        }
      },
    }
  );

  return (
    <>
      <Navbar />

      <Page>
        <BoxContainer>
          <div className="flex w-full justify-between items-center">
            <div>
              <Title>Activity Logs of {blNumber}</Title>
              <Underline />
            </div>
          </div>

          {!error && !isLoading && data && !isRefetching && (
            <TableWrapper>
              <ActivityLogTable
                ApiData={error ? [] : formData}
                setSelectedData={setSelectedData}
                selectedData={selectedData}
              />
            </TableWrapper>
          )}

          {(isLoading || isRefetching) && <Loading />}
          {(error || formData == 0) && !isLoading && !isRefetching && (
            <NotFoundModel />
          )}
        </BoxContainer>
      </Page>
    </>
  );
};

const TableWrapper = tw.h1`my-10 border bg-white   rounded-md overflow-auto`;
const Title = tw.h1`text-xl  text-gray-800 whitespace-nowrap   font-medium`;
export default ActivityLogs;
