import React, { useState, useEffect } from "react";
import Images from "../../Images";
import tw from "tailwind-styled-components";
import Config from "../../Config";
import axios from "axios";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Field, Formik, Form } from "formik";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "react-query";
import Navbar from "../../Components/Navbar";
import Loading from "react-loading";
import {
  InputGroup,
  FieldWrapper,
  Label,
  CombineInputGroup,
  CombineInputThreeGroup,
  CombineInputGridGroup,
  SubmitBtn,
} from "../../Components/Styles/InputStyles";
import {
  Bg,
  BoxContainer,
  FieldCustomWrapperDoc,
  Page,
} from "../../Components/Styles/PageStyles";
import { useTranslation } from "react-i18next";
import DropZone from "../../Components/DropZonePdf";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
const AddClient = () => {
  const user = useSelector((state) => state.UserReducer.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { id } = useParams();

  const [isProductionFetched, setIsProductionFetched] = useState(false);

  const EditProduct = async () =>
    await axios.get(`${Config.apiUrl}/get-client/${id}`, {
      headers: {
        Authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });

  const { data, isLoading: productionLoading } = useQuery(
    "get-production",

    EditProduct,
    {
      enabled: !isProductionFetched,
    }
  );

  useEffect(() => {
    if (data?.data && !isProductionFetched) {
      setIsProductionFetched(true);
    }
  }, [isProductionFetched, data]);
  // console.log(data);
  //------- Add Single Entry -------
  const AddUserFunction = async (values) => {
    const body = new FormData();
    body.append("id", values.id);
    body.append("name", values.name);
    body.append("mobile", values.mobile);
    body.append("companyName", values.companyName);
    body.append("email", values.email);
    body.append("address", values.address);
    body.append("ladingBill", values.ladingBill);
    body.append("blNumber", values.blNumber);
    body.append("paymentReceipt", values.paymentReceipt);
    body.append("invoice", values.invoice);
    body.append("packageInformation", values.packageInformation);
    body.append("processType", values.processType);
    return await axios.post(`${Config.apiUrl}/add-client-doc`, body, {
      headers: {
        authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const onAddUserSuccess = (data, values) => {
    console.log(values.processType);
    if (data?.data?.status == "OK") {
      toast.success(data?.data?.msg || "Success");
      if (values?.processType == Config.processType.Automatic) {
        navigate("/client-list");
      } else if (values?.processType == Config.processType.Manually) {
        // navigate(`/manually-form/${values.blNumber}`);
        navigate(`/manually-form/${values.blNumber}`, {
          state: {
            name: values.name,
            address: values.address,
            companyName: values.companyName,
          },
        });
        console.log(values.blNumber);
      }
    } else {
      toast.error(data?.data?.msg);
    }
  };

  const onAddUserError = (data) => {
    toast.error(data?.response?.data?.msg || "An Error Occurred");
  };

  const { isLoading: AddSubAgentLoading, mutate: postUser } = useMutation(
    AddUserFunction,
    {
      onSuccess: onAddUserSuccess,
      onError: onAddUserError,
    }
  );

  const SubmitHandler = (values) => {
    if (!values.blNumber) {
      toast.error("Please enter your B/L number ");
      return;
    }
    if (!values.processType) {
      toast.error("Please select a process type");
      return;
    }
    postUser({ ...values });
  };

  const InitialValue = {
    name: data?.data?.data?.details?.name,
    mobile: data?.data?.data?.details?.mobile,
    ladingBill: "",
    blNumber: "",
    paymentReceipt: "",
    invoice: "",
    packageInformation: "",
    companyName: data?.data?.data?.details?.companyName,
    email: data?.data?.data?.details?.email,
    address: data?.data?.data?.details?.address,
    id: id,
    processType: "1",
  };
  return (
    <>
      <Navbar />
      <Page>
        <BoxContainer>
          <Box>
            <div className="flex w-full justify-center items-center">
              <div>
                <Title>Add BL Number and Upload</Title>
                <Underline />
              </div>
            </div>
            {/* <TextWrapper> */}
            {!productionLoading && (
              <Formik
                initialValues={InitialValue}
                onSubmit={SubmitHandler}
                enableReinitialize
              >
                <>
                  <Form>
                    <Wrapper>
                      <CombineInputThreeGroup>
                        <InputGroup>
                          <Label htmlFor="companyName">Company Name</Label>
                          <FieldWrapper className="bg-gray-100">
                            <Field
                              type="text"
                              name="companyName"
                              id="companyName"
                              placeholder="Company Name"
                              autoComplete="off"
                              className="truncate "
                              required
                              readOnly
                            />
                          </FieldWrapper>
                        </InputGroup>

                        <InputGroup>
                          <Label htmlFor="processType">Process Type</Label>
                          <FieldWrapper $select={true}>
                            <Field
                              required
                              name="processType"
                              id="processType"
                              autoComplete="off"
                            >
                              {(props) => (
                                <Select
                                  className="w-full h-full pl-2"
                                  {...props.field}
                                  value={props.field.value || "1"}
                                >
                                  <MenuItem value="" disabled>
                                    Select process type
                                  </MenuItem>
                                  <MenuItem value="1">Automate</MenuItem>
                                  <MenuItem value="2">Manually</MenuItem>
                                </Select>
                              )}
                            </Field>
                          </FieldWrapper>
                        </InputGroup>

                        <InputGroup>
                          <Label htmlFor="blNumber">B/L Number</Label>
                          <FieldWrapper>
                            <Field
                              type="text"
                              name="blNumber"
                              id="blNumber"
                              // placeholder="B/L Number"
                              autoComplete="off"
                              className="truncate"
                              required
                            />
                          </FieldWrapper>
                        </InputGroup>
                      </CombineInputThreeGroup>

                      <CombineInputGridGroup>
                        <InputGroup>
                          <Label htmlFor="ladingBill">
                            Bill of Lading(BOL)
                          </Label>
                          <FieldCustomWrapperDoc $select={true}>
                            <Field
                              name="ladingBill"
                              type="text"
                              id="ladingBill"
                              autoComplete="off"
                              required
                            >
                              {(props) => (
                                <DropZone
                                  fields={props.field}
                                  setFieldValue={props.form.setFieldValue}
                                  componentFor="ladingBill"
                                  acceptFiles="application/pdf"
                                  File={
                                    props.field.value
                                      ? [props.field.value]
                                      : null
                                  }
                                />
                              )}
                            </Field>
                          </FieldCustomWrapperDoc>
                          <p className="text-xs text-gray-500 mt-2 ">
                            Max size: 10MB
                          </p>
                        </InputGroup>
                        <InputGroup>
                          <Label htmlFor="paymentReceipt">
                            Payment Receipt
                          </Label>
                          <FieldCustomWrapperDoc $select={true}>
                            <Field
                              name="paymentReceipt"
                              type="text"
                              id="paymentReceipt"
                              autoComplete="off"
                              required
                            >
                              {(props) => (
                                <DropZone
                                  fields={props.field}
                                  setFieldValue={props.form.setFieldValue}
                                  componentFor="paymentReceipt"
                                  acceptFiles="application/pdf"
                                  File={
                                    props.field.value
                                      ? [props.field.value]
                                      : null
                                  }
                                />
                              )}
                            </Field>
                          </FieldCustomWrapperDoc>
                          <p className="text-xs text-gray-500 mt-2 ">
                            Max size: 10MB
                          </p>
                        </InputGroup>
                      </CombineInputGridGroup>

                      <InputGroup>
                        <Label htmlFor="invoice">Invoice</Label>
                        <FieldCustomWrapperDoc $select={true}>
                          <Field
                            name="invoice"
                            type="text"
                            id="invoice"
                            autoComplete="off"
                            required
                          >
                            {(props) => (
                              <DropZone
                                fields={props.field}
                                setFieldValue={props.form.setFieldValue}
                                componentFor="invoice"
                                acceptFiles="application/pdf"
                                File={
                                  props.field.value ? [props.field.value] : null
                                }
                              />
                            )}
                          </Field>
                        </FieldCustomWrapperDoc>
                        <p className="text-xs text-gray-500 mt-2 ">
                          Max size: 10MB
                        </p>
                      </InputGroup>
                      <InputGroup>
                        <Label htmlFor="packageInformation">
                          Package Information
                        </Label>
                        <FieldWrapper>
                          <Field
                            component="textarea"
                            row="10"
                            type="textarea"
                            name="packageInformation"
                            id="packageInformation"
                            autoComplete="off"
                            // required
                          />
                        </FieldWrapper>
                      </InputGroup>
                    </Wrapper>
                    <BtnWrapper>
                      <SubmitBtn type="submit" disabled={AddSubAgentLoading}>
                        {" "}
                        {AddSubAgentLoading ? (
                          <Loading
                            color="white"
                            width={20}
                            height={20}
                            noPadding={true}
                          />
                        ) : (
                          t("Submit")
                        )}
                      </SubmitBtn>
                    </BtnWrapper>
                  </Form>
                </>
              </Formik>
            )}
            {productionLoading && <Loading />}
            {/* </TextWrapper> */}
          </Box>
        </BoxContainer>
      </Page>
      {/* </Bg> */}
    </>
  );
};

const TextWrapper = tw.div`h-full w-full  flex justify-center`;
const Box = tw.div`border border-gray-100 py-4 md:py-8  px-4 md:px-20 shadow-md rounded-md`;
const Title = tw.h1`text-xl  text-gray-800 whitespace-nowrap   font-medium`;
const UpdateBtn = tw.button`
text-sm  w-32  md:w-60 h-12 grid place-items-center disabled:opacity-50 disabled:cursor-not-allowed   md:text-base bg-custom-green hover:bg-green-700 text-white rounded-md shadow-md md:mx-44`;
const BtnWrapper = tw.div`flex justify-center items-center mt-8`;
const Wrapper = tw.div`
grid  gap-6 my-6 px-1`;

const Underline = tw.div`w-20 mx-auto h-0.5 bg-gray-700 mt-1.5 rounded-full `;

export default AddClient;
